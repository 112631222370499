import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import StoreContext, { defaultStoreContext } from '../context/StoreContext'
// import { ThemeContext } from "../layouts";
import Blog from '../components/Blog'
import BlogSidebar from '../components/Blog/BlogSidebar'
// import Hero from "../components/Hero";
import SEO from '../components/SEO'
import PageHeader from '../components/Shared/PageHeader'

class BlogPage extends React.Component {
  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        bgDesktop: {
          resize: { src: desktop },
        },
        bgTablet: {
          resize: { src: tablet },
        },
        bgMobile: {
          resize: { src: mobile },
        },
        site: {
          siteMetadata: { facebook },
        },
      },
    } = this.props

    const backgrounds = {
      desktop,
      tablet,
      mobile,
    }

    const breadcrumb = [
      { title: 'Home', url: '/' },
      { title: 'Blog', url: '/blog' },
    ]

    return (
      <React.Fragment>
        <PageHeader header="Blog" breadcrumb={breadcrumb} />

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="block">
                <div className="posts-view">
                  <div className="posts-view__list posts-list posts-list--layout--classic">
                    <div className="posts-list__body">
                      <StoreContext.Consumer>
                        {theme => <Blog posts={posts} theme={theme} />}
                      </StoreContext.Consumer>
                    </div>
                  </div>
                  {/*<div className="posts-view__pagination">
                        <Pagination
                            current={page}
                            siblings={2}
                            total={10}
                            onPageChange={this.handlePageChange}
                        />
                    </div>*/}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <BlogSidebar position="end" />
            </div>
          </div>
        </div>

        <SEO title="Blog" />
      </React.Fragment>
    )
  }
}

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPage

//eslint-disable-next-line no-undef
export const query = graphql`
  query BlogQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(
      fluid: { originalName: { regex: "/hero-background/" } }
    ) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(
      fluid: { originalName: { regex: "/hero-background/" } }
    ) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(
      fluid: { originalName: { regex: "/hero-background/" } }
    ) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`

//hero-background
